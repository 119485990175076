<template>
  <div class="partner-prices">
    <custom-scrollbar>
      <div class="crm-container">
        <loading
          v-if="isLoading"
          class="mt-4"
        >
          Загрузка
        </loading>

        <div
          v-if="!isLoading"
          class="crm-header-block"
        >
          <div class="crm-title">
            Импорт прайсов
          </div>
          <div class="crm-form-row">
            <b-row>
              <b-col>
                <b-form-group
                  label="Выбор партнёра"
                >
                  <v-select
                    v-model="data.partnerId"
                    :reduce="partner => partner.id"
                    placeholder="Выберите партнёра"
                    :options="legalPartners"
                    label="legalName"
                    class="crm-form-field"
                    @input="changeParthersValue(data.partnerId)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Тип прайса"
                >
                  <b-form-input
                    :value="priceType"
                    type="text"
                    placeholder="Тип прайса"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Дата прайса">
                  <base-date-picker
                    v-model="$v.data.startDate.$model"
                    :error="$v.data.startDate.$error"
                    placeholder="Выберите дату"
                    :disabled="!data.partnerId"
                    class="crm-form-date-picker"
                    :min-date="new Date()"
                  />
                  <div
                    v-if="!data.startDate"
                    class="mt-1 text-danger small"
                  >
                    {{ errorsValidation.startDate[0] }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Тип импорта"
                >
                  <v-select
                    v-model="$v.data.importType.$model"
                    :reduce="importType => importType.id"
                    placeholder="Выберите тип импорта"
                    :options="importTypeOptions"
                    label="text"
                    class="crm-form-field w-75"
                    :disabled="!data.partnerId"
                    @input="onImportTypeChange"
                  />
                  <div
                    v-if="!data.importType"
                    class="mt-1 text-danger small"
                  >
                    {{ errorsValidation.importType[0] }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="crm-container-buttons">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              class="ml-auto"
              size="sm"
              :disabled="!data.partnerId"
              @click="onClickOpenFileModal"
            >
              <icon-cross
                class="crm-icon-cross"
              />
              Добавить файл
            </b-button>
            <b-button
              variant="info"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="!data.partnerId"
              @click="onClickOpenImportModal"
            >
              Загрузить файл
            </b-button>
          </div>
        </div>
        <div
          v-if="!isLoadingFromFile"
          class="partner-prices-table"
        >
          <div class="partner-prices-table-wrapper">
            <div
              ref="table-header"
              class="partner-prices-table-header"
            >
              <div
                v-for="(title, i) in selectedColumnNames"
                :key="i"
                class="partner-prices-table-header-cell"
              >
                <v-select
                  :value="filteredValueInTabte(i)"
                  :append-to-body="true"
                  :reduce="name => name.target"
                  :searchable="false"
                  :clearable="true"
                  :options="filteredAvailableColumnNames"
                  placeholder="Игнорировать"
                  :calculate-position="setMaxHeight"
                  label="name"
                  :disabled="!fileGUID"
                  class="select-col-name"
                  @input="selectColumnName($event, i)"
                />
              </div>
            </div>
            <div class="partner-prices-table-body">
              <vuescroll
                :ops="ops"
                class="vuescroll-custom"
                @handle-scroll="handleScroll"
              >
                <div class="partner-prices-table-inner">
                  <div
                    v-for="(row, rowIndex) in tableRows"
                    :key="rowIndex"
                    class="partner-prices-table-row"
                  >
                    <input
                      v-for="(cell, cellIndex) in row"
                      :key="cellIndex"
                      readonly="readonly"
                      class="partner-prices-table-cell"
                      type="text"
                      :value="cell"
                    >
                  </div>
                </div>
              </vuescroll>
            </div>
          </div>
          <div
            v-if="tableRows.length < totalRowsInFile"
            class="show-more-btn"
            @click="showAllRows"
          >
            Показать полностью
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>
<script>
import { BaseDatePicker } from '@/components/base';
import { required } from 'vuelidate/lib/validators';
import Loading from '@/components/Loading';
import { api } from '@/helpers/api';
import vuescroll from 'vuescroll';
import { showValidationErrorCustomMessage } from '@/helpers/messages';
import IconCross from 'assets/images/cross.svg';

const AGENCY_CONTRACT_TEXT = 'Агентский договор';
const SERVICE_CONTRACT_TEXT = 'Договор оказания услуг';
const COLS = 5;
const ROWS = 8;

export default {
  name: 'ImportPartnerPrices',
  components: {
    BaseDatePicker,
    Loading,
    IconCross,
    vuescroll,
  },
  data() {
    return {
      isLoading: false,
      priceType: '',
      saveFlag: false,
      importTypeOptions: [
        {
          id: 0,
          text: 'Добавить',
        },
        {
          id: 1,
          text: 'Обновить',
        },
        {
          id: 2,
          text: 'Удалить',
        },
      ],
      data: {
        partnerId: null,
        startDate: null,
        importType: null,
      },
      fileKeyAfterUpload: null,
      isLoadingFromFile: false,
      columnNumber: null,
      fileGUID: undefined,
      fileName: undefined,
      availableColumnNames: [],
      filteredAvailableColumnNames: [],
      miniDisplayedRows: 10,
      totalRowsInFile: 0,
      selectedColumnNames: [],
      tableRows: [],
      ops: {
        vuescroll: {
          mode: 'native',
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: true,
        },
        rail: {
          opacity: 0,
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#E0E0E0',
          minSize: 0.15,
          size: '5px',
        },
      },
    };
  },
  validations() {
    return {
      data: {
        startDate: {
          required: !this.data.startDate && this.data.partnerId ? required : () => true,
        },
        importType: {
          required: !this.data.importType && this.data.partnerId ? required : () => true,
        },
      },

    };
  },
  computed: {
    legalPartners() {
      return this.$store.state.Laboratories.legalPartnerList.data;
    },
    legalPartner() {
      return this.legalPartners.find((item) => item.id === this.data.partnerId);
    },
    errorsValidation() {
      const errors = {};

      errors.startDate = [];
      if (!this.$v.data.startDate.required) {
        errors.startDate.push('Необходимо выбрать дату прайса');
      }

      errors.importType = [];
      if (!this.$v.data.importType.required) {
        errors.importType.push('Необходимо выбрать тип импорта');
      }
      return errors;
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchLegalPartners();
    this.filteredAvailableColumnNames = this.availableColumnNames;
    this.tableRows = this.generateTableRows(COLS, ROWS);
    this.selectedColumnNames = this.generateSelectedColumnNames(COLS, null);
    this.isLoading = false;
  },
  methods: {
    generateTableRows(col, row) {
      return new Array(row).fill(new Array(col).fill(''));
    },
    generateSelectedColumnNames(quantity, value) {
      return new Array(quantity).fill(value);
    },
    filteredValueInTabte(i) {
      return this.availableColumnNames.filter((name) => name.target === this.selectedColumnNames[i])[0];
    },
    onCheckValidation() {
      this.$v.data.startDate.$touch();
      this.$v.data.importType.$touch();
      if (
        this.$v.data.startDate.$anyError
        || this.$v.data.importType.$anyError
      ) {
        return false;
      }

      return true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.data[name];
      return $dirty ? !$error : null;
    },
    changeParthersValue(value) {
      if (value !== null) {
        this.data.importType = null;
        this.data.startDate = null;
        this.saveFlag = false;
      }

      const partner = this.legalPartners.find((item) => item.id === value);

      if (partner?.contractType.includes('Agency')) {
        this.priceType = AGENCY_CONTRACT_TEXT;
      } else if (partner?.contractType.includes('Service')) {
        this.priceType = SERVICE_CONTRACT_TEXT;
      } else {
        this.priceType = '';
      }
    },
    onClickOpenFileModal() {
      if (!this.onCheckValidation()) return;
      this.saveFlag = false;

      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'FileModalModernNew',
        props: {
          saveFileCallback: this.saveFileCallback,
          multiple: false,
          fileApi: 'v3/laboratory/import/partner-services/upload-file',
          type: '.xlsx',
        },
      });
      this.selectedColumnNames = this.generateSelectedColumnNames(COLS, null);
      this.getColumnsList();
    },
    onImportTypeChange(e) {
      if (e === null) return;
      this.getColumnsList();
    },
    async getColumnsList() {
      this.saveFlag = false;
      const { data: { data } } = await
      api.get(`v3/laboratory/import/partner-services/columns?legalPartnerId=${this.data.partnerId}
      &importType=${this.data.importType}`);
      this.availableColumnNames = data;
      this.filteredAvailableColumnNames = this.availableColumnNames;
    },
    async fetchLegalPartners() {
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
      this.allCount = data.xTotalCount;
    },
    async onClickOpenImportModal() {
      if (!this.onCheckValidation()) return;
      this.saveFlag = false;
      const hasFile = this.tableRows.length && this.totalRowsInFile > 0 && !this.isLoadingFromFile;
      if (!hasFile) {
        showValidationErrorCustomMessage('Необходимо добавить файл');
        return;
      }

      if (this.filteredAvailableColumnNames.length !== 0) {
        showValidationErrorCustomMessage('Выбраны не все поля');
        return;
      }

      const columns = this.selectedColumnNames.map((target, i) => {
        const container = {};
        container.target = this.availableColumnNames.find((col) => col.target === target);
        container.number = i + 1;
        return container;
      }).filter((item) => item.target !== undefined).map((item) => ({
        ...item,
        target: Number(item.target.target),
      }));

      const { data } = await this.$store.dispatch(this.$types.LABORATORY_DOWNLOAD_PARTNER_PRICES, {
        fileKey: this.fileGUID,
        legalPartnerId: this.data.partnerId,
        startedAt: this.data.startDate,
        importType: this.data.importType,
        columns,
      });
      if (data.hasErrors) {
        showValidationErrorCustomMessage('Файл импортирован с ошибками');
      }

      this.openUploadImportPrices(data);

      this.data.importType = null;
      this.selectedColumnNames = this.generateSelectedColumnNames(COLS, null);
      this.getTableRows(10, true);
      this.saveFlag = true;
    },
    async saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;
      this.fileGUID = newFile.guid?.data?.fileKey;
      this.fileName = newFile.name;
      await this.getTableRows(10, true);
    },

    setMaxHeight(dropdownList, component, { width, top, left }) {
      dropdownList.style.maxHeight = `calc(100vh - ${top} - 20px)`;
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    handleScroll(vertical, horizontal) {
      const header = this.$refs['table-header'];
      const headerScrollValue = Math.round(horizontal.process * (header.scrollWidth - header.clientWidth));
      header.scrollLeft = headerScrollValue;
    },
    showAllRows() {
      this.getTableRows(this.totalRowsInFile, false);
    },
    generateEmptyRows(rowsCount, colsCount) {
      const rows = new Array(rowsCount).fill([]);

      for (let i = 0; i < rowsCount; i += 1) {
        rows[i] = new Array(colsCount).fill('');
      }

      return rows;
    },

    async getTableRows(limitRows, needClearSelectedColumns) {
      this.isLoadingFromFile = true;
      try {
        const { data: { rows, allRowsCount } } = await api.get(`v2/Excel/rows-new?key=${this.fileGUID}&take=${limitRows}`);
        this.totalRowsInFile = allRowsCount;
        const rowsCount = rows.length;
        const colsCount = rows[0].length;
        const rowsCountDelta = this.miniDisplayedRows - rowsCount;
        if (needClearSelectedColumns) {
          if (this.selectedColumnNames.length > rows[0].length) {
            this.selectedColumnNames = this.selectedColumnNames.slice(0, rows[0].length);
          } else if (this.selectedColumnNames.length < rows[0].length) {
            const newColumns = new Array(rows[0].length - this.selectedColumnNames.length).fill(null);
            this.selectedColumnNames = [...this.selectedColumnNames, ...newColumns];
          }
        }
        this.tableRows = rowsCountDelta > 0 ? [...rows, ...this.generateEmptyRows(rowsCountDelta, colsCount)] : rows;
      } catch (e) {
        console.log('cant get rows from file');
      }
      this.isLoadingFromFile = false;
    },
    selectColumnName(e, i) {
      this.selectedColumnNames[i] = e;
      this.columnNumber = i + 1;
      this.filteredAvailableColumnNames = this.availableColumnNames.filter((columnName) => !this.selectedColumnNames.includes(columnName.target));
    },
    openUploadImportPrices(data) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AddPartnerPrices',
        props: {
          data,
          fileName: this.fileName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-prices {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.crm-container {
  flex-direction: column;
}

.crm-header-block {
  margin-top: 20px;
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.crm-title {
  @extend .page-title;
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }
}
.crm-form-row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  grid-column-gap: 20px;
  margin-top: 20px;
  width: 100%;
}
.crm-container-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding-right: 75px;
  gap: 15px;
}

.partner-prices-table {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px  55px 30px;

  * {
    box-sizing: border-box;
  }
}

partner-prices-table-wrapper {
  width: 100%;
}

.partner-prices-table-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.partner-prices-table-header-cell {
  padding: 0 10px;
  width: 250px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  flex-shrink: 0;

  .crm-icon-dropdown {
    margin-left: 10px;
  }
}
.show-more-btn {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    color: lighten($blue, 10);
  }
}

.partner-prices-table-body {
  height: 350px;
  position: relative;
}

::v-deep.partner-prices-table-row {
  display: flex;
  position: relative;
  flex: 1;
  border-bottom: 1px solid #E0E0E0;
  //& + .policies-table-row {
  //  border-bottom: 1px solid #E0E0E0;
  //}
}

//.policies-table-row-delete-list {
//  position: absolute;
//  left: -50px;
//  top: 0;
//  bottom: 0;
//  z-index: 1;
//  overflow: hidden;
//}

.partner-prices-table-button-row-delete {
  height: 40px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep.partner-prices-table-cell {
  width: 250px;
  flex-shrink: 0;
  border: none;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  padding: 10px;

  &:focus {
    outline: 1px solid $blue;
    position: relative;
    z-index: 1;
  }

  & + .partner-prices-table-cell {
    border-left: 1px solid #E0E0E0;
  }
}

.select-col-name {
  width: 250px;
}

::v-deep.select-col-name {
  .vs__dropdown-toggle {
    border: 0px !important;
    padding-bottom: 0px;
  }
  .vs__selected-options {
    align-items: center;

    input::placeholder {
      font-size: 16px;
      color: #6E88F3;
      font-weight: 500;
    }
  }
  .vs__selected {
    font-size: 16px;
    color: #6E88F3;
    font-weight: 500;
  }
}

.vuescroll-custom {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.width-unset {
  width: auto;
}

::v-deep.specialization-filter {
  .crm-checkbox-list-wrapper {
    height: 350px !important;
  }
}

.crm-item-wrapper {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .crm-item {
    margin-right: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: auto;
    }
  }
}
.crm-header-group {
  display: flex;
  justify-content: space-between;
}

.specialization-filter {
  position: relative;
}

</style>
